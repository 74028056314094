import * as React from "react"
import MainLayout from '../layouts/main'
import {GatsbyImage} from "gatsby-plugin-image";
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../components/Breadcrumbs";
import Partners from "./home/partners";
import ContributiFiscali from "./home/contributi-fiscali";
import Help from "./home/help";
import HeroPage from "../components/HeroPage";
import SeoComponent from "../layouts/partials/SeoComponent";

const items = [
    { title: 'News' }
]
const News = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(news)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]      
                        placeholder: NONE         
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [installations, setInstallations] = useState({});
    useEffect(() => {
        setInstallations({
            product: [
                {
                    name: 'GREEN LIFE: UN BRAND DI GAMMA',
                    image: 'greenlife-news.jpg',
                    description: 'Gamma Tendaggi e Tessuti  ha lanciato un nuovo brand: Green Life, nato dal riciclo. È un progetto tessile sostenibile che prevede prodotti ad impatto ambientale zero, che nascono dal recupero della plastica dal recupero dei tessuti.'
                },
                {
                    name: 'ENERGY: NUOVA PERGOLA A RISPARMIO ENERGETICO',
                    image: 'energy.jpg',
                    description: 'Dotate di pannelli fotovoltaici per rendere la luce del sole una fonte di produzione energetica. La possibilità di orientare liberamente le lame frangisole risulta un perfetto ausilio per una migliore orientazione dei moduli fotovoltaici rispetto ai raggi solari.'
                },
                {
                    name: 'VELVET: PERGOLA BIOCLIMATICA AD IMPACCHETTAMENTO',
                    image: 'GIBUS_VELVET_PLUS_4.jpg',
                    description: 'Adesso anche le pergole bioclimatiche possono essere impacchettabili: Le lame si impacchettano con movimento indipendente rispetto alla rotazione, permettendo pieno controllo e liberando lo sguardo verso il cielo.'
                },
                {
                    name: 'Pergole bioclimatiche',
                    image: 'news_pergola_bioclimatica.jpg',
                    description: 'Pergola a lame orientabili motorizzata per ottenere il massimo comfort e perfetta tenuta in caso di pioggia perché le lame sono state progettate per impermeabilizzare al meglio e consentire il deflusso dell\'acqua solo negli appositi pluviali di scarico integrati nelle colonne della struttura. Possibilità di illuminazione integrata con led.'
                },
                {
                    name: 'Tenda a bracci Cassonata',
                    image: 'news_cassonata.jpg',
                    description: 'Le tende a bracci cassonate sono progettate per ospitare il telo all\'interno di un cassonetto di alluminio. Quando la tenda si chiude, il frontale si incastra perfettamente con il cassonetto garantendo la massima protezione dagli agenti atmosferici e un\'estetica minimale e pulita.'
                },
                {
                    name: 'Tende in trazione',
                    image: 'news_tenda_in_trazione.jpg',
                    description: 'Nuovo sistema per tende in trazione senza frangivento. Vantaggi: facili da installare, pratiche da aprire e chiudere con le guide e con il sistema motorizzato di serie, protetto insieme al telo dal cassonetto e senza alcun "sfarfallamento" grazie alle molle a gas che tengono il telo teso.'
                },
                {
                    name: 'Nuovi arrivi! Cotoni stampati!',
                    image: 'news_cotoni_colorati.jpg',
                    description: 'Cotoni stampati, colorati, festosi per rinnovare la camerina, il soggiorno, la zona relax. Vieni a visitare il nostro showroom per poterli vedere e toccare dal vivo.'
                },
            ],
        })
    }, [gContext])
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'parallax-news.jpg')}
                imageAlt={'parallax-news.jpg'}
                title={'News'}
            />
            <Breadcrumps  items={items}/>

            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <div className="divide-y divide-black">
                    {(installations?.product || []).map((item) => (
                        <div key={item.name} className="py-10 md:py-16 grid grid-cols-1 md:grid-cols-12 gap-4 sm:gap-8 md:gap-16">
                            <div className="md:col-span-6 lg:col-span-7 md:col-start-1 flex flex-col items-start">
                                <h2 className="title-section-light">{gContext.t(item.name)}</h2>
                                <p className="mt-4 sm:mt-8 sm:mb-8 text-justify">{gContext.t(item.description)}</p>
                            </div>
                            <div className="md:col-span-6 lg:col-span-5 md:col-start-7 lg:col-start-8 flex items-center justify-center">
                                <GatsbyImage image={gContext.getImageFromName(images, item.image)}
                                             alt={item.image}
                                             loading="eager"
                                             objectFit="contain"
                                             objectPosition="50% 50%"
                                             className="mx-auto w-full sm:w-[312px] rounded-xl" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default News

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)